import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { Clientes } from '../pages/financeiro/clientes/Clientes'
import { Credenciados } from '../pages/financeiro/credenciados/Credenciados'
import RelatorioMovimentoCredenciado from '../pages/financeiro/relatorio-movimento-credenciado/RelatorioMovimentoCredenciado'
import FinanceiroRelatorioRecargaCliente from '../pages/financeiro/relatorio-recarga/FinanceiroRelatorioRecargaCliente'
import FinanceiroRelatorioRecargaGrupo from '../pages/financeiro/relatorio-recarga/FinanceiroRelatorioRecargaGrupo'

export function FinanceiroRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/financeiro/clientes' component={Clientes} />
        <Route exact path='/financeiro/credenciado/movimentos' component={RelatorioMovimentoCredenciado} />
        <Route exact path='/financeiro/cliente/recargas' component={FinanceiroRelatorioRecargaCliente} />
        <Route exact path='/financeiro/grupo/recargas' component={FinanceiroRelatorioRecargaGrupo} />
        <Route exact path='/financeiro/credenciados' component={Credenciados} />
        <Redirect from='/' to='/financeiro/clientes' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
