import { IApiResult } from "../interfaces/responses/common/IApiResult";
import api from "./Api";
import { IFinanceiroCliente } from "../interfaces/responses/financeiro/IFinanceiroCliente";
import { IFinanceiroCredenciado } from "../interfaces/responses/financeiro/IFinanceiroCredenciado";
import { IFinanceiroGrupoEmpresarial } from "../interfaces/responses/financeiro/IFinanceiroGrupoEmpresarial";
import { IFinanceiroEditarCredenciado } from "../interfaces/request/financeiro/IFinanceiroEditarCredenciado";
import { IRelatorioMovimentoCredenciado } from "../interfaces/responses/financeiro/IRelatorioMovimentoCredenciado";
import { IRelatorioRecargaCliente, IRelatorioRecargaGrupo } from "../interfaces/responses/financeiro/IRelatorioRecargaCliente";

class FinanceiroService {

    async relatorioRecargaGrupo(idGrupoEmpresarialCliente: string,dataInicio: string, dataFim: string): Promise<IApiResult<IRelatorioRecargaGrupo[]>> {
        let { data: result } = await api.get<IApiResult<IRelatorioRecargaGrupo[]>>(`financeiro/relatorio-recarga-grupo-por-periodo`,
        {
            params:  { 
                        idGrupoEmpresarialCliente: idGrupoEmpresarialCliente,
                        dataInicial: dataInicio, 
                        dataFinal: dataFim
            }
        });

        return result;
    }

    async relatorioRecargaCliente(cnpj:string, codigo: string, idGrupoEmpresarialCliente: string,dataInicio: string, dataFim: string): Promise<IApiResult<IRelatorioRecargaCliente[]>> {
        let { data: result } = await api.get<IApiResult<IRelatorioRecargaCliente[]>>(`financeiro/relatorio-recarga-cliente-por-periodo`,
        {
            params:  {
                        cnpj: cnpj, 
                        codigo: codigo, 
                        idGrupoEmpresarialCliente: idGrupoEmpresarialCliente,
                        dataInicial: dataInicio, 
                        dataFinal: dataFim
            }
        });

        return result;
    }

    async relatorioMovimentoCredenciado(cnpj:string, codigo: string, idGrupoEmpresarialCredenciado: string, idGrupoEmpresarialCliente: string,dataInicio: string, dataFim: string): Promise<IApiResult<IRelatorioMovimentoCredenciado[]>> {
        let { data: result } = await api.get<IApiResult<IRelatorioMovimentoCredenciado[]>>(`financeiro/relatorio-movimento-por-periodo`,
        {
            params:  {
                        cnpj: cnpj, 
                        codigo: codigo, 
                        idGrupoEmpresarialCredenciado: idGrupoEmpresarialCredenciado, 
                        idGrupoEmpresarialCliente: idGrupoEmpresarialCliente,
                        dataInicial: dataInicio, 
                        dataFinal: dataFim
            }
        });
        return result;
    }

    async obterCredenciados(cnpj:string, codigo: string, idGrupoEmpresarial: string): Promise<IApiResult<IFinanceiroCredenciado[]>> {
        let { data: result } = await api.get<IApiResult<IFinanceiroCredenciado[]>>('financeiro/obter-credenciados', 
        {
            params: {cnpj: cnpj, codigo: codigo, idGrupoEmpresarial: idGrupoEmpresarial}
        });

        return result;
    }

    async obterClientes(cnpj:string, codigo: string, idGrupoEmpresarial: string): Promise<IApiResult<IFinanceiroCliente[]>> {
        let { data: result } = await api.get<IApiResult<IFinanceiroCliente[]>>('financeiro/obter-clientes', 
        {
            params: {cnpj: cnpj, codigo: codigo, idGrupoEmpresarial: idGrupoEmpresarial}
        });

        return result;
    }

    async obterGruposEmpresariais(): Promise<IApiResult<IFinanceiroGrupoEmpresarial[]>> {
        let { data: result } = await api.get<IApiResult<IFinanceiroGrupoEmpresarial[]>>('financeiro/obter-grupos')

        return result;
    }

    async editarCredenciado(credenciado: IFinanceiroEditarCredenciado): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`financeiro/editar-credenciado/${credenciado.id}`, credenciado);

        return result;
    }
}

export default new FinanceiroService();