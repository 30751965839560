import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image  } from '@react-pdf/renderer';
import { PDFViewer, PDFDownloadLink  } from '@react-pdf/renderer';
import * as Yup from 'yup';
import { useFormik } from "formik";
import ClienteService from '../../../services/ClienteService';
import Swal from 'sweetalert2';
import { ExtratoCreditosDto, IRelatorioCreditoFuncionarioResponse } from '../../../interfaces/responses/clientes/IRelatorioCreditoFuncionarioResponse';
import InputCPF from '../../components/InputCPF';
import BotaoSubmitFormulario from '../../components/BotaoSubmitFormulario';
import HeaderContent from '../../components/HeaderContent';
import PainelFiltro from '../../components/PainelFiltro';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 10,
      padding: 0,
      backgroundColor: '#ffffff',
      lineHeight: 1.5
    },
    header: {
      backgroundColor: '#00A946',
      color: '#ffffff',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerRow: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    headerTitle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10,
    },
    column: {
      width: '48%',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 4,
    },
    body: {
      marginVertical: 20,
      padding: 10
    },
    table: {
      display: 'flex',
      width: '100%',
      borderWidth: 1,
      borderColor: '#000',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCell: {
      flex: 1,
      padding: 5,
      borderWidth: 1,
      borderColor: '#000',
    },
    footer: {
      position: 'absolute', // Fixa no rodapé
      bottom: 0,
      left: 10,
      top: 10,
      textAlign: 'center', // Centraliza o texto
      fontSize: 10,
    },
    logo: {
      width: 25,
      height: 25,
      position: 'absolute',
      top: 10,
      right: 10,
    },
    empresaHeader: {
      fontWeight: 'bold',
      padding: 10
    },
    produto: {
      padding: 10,
    },
});

interface RelatorioRecargaPdfProps {
  data: ExtratoCreditosDto;
}

const RelatorioRecargaPdf = ({ data }: RelatorioRecargaPdfProps) => (
    <Document>
    <Page size="A4" orientation='portrait' style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Extrato Total de Créditos</Text>
        <View style={styles.headerRow}>
            <View style={styles.column}>
                <Text>Funcionário: {data.funcionarioNome}</Text>
                <Text>Valor Total de Créditos: {data.valorTotalCreditoFormatado}</Text>
            </View>
            <View style={styles.column}>
                <Text>Documento: {data.funcionarioCpf}</Text>
                <Text>Cartões Emitidos: {data.cartoesEmitidos}</Text>
            </View>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
        />
        <Image
          style={styles.logo}
          src='https://qbcard-public.s3.sa-east-1.amazonaws.com/logo/logo-sem-letra.png'
        />
      </View>

      {/* Body */}
      <View style={styles.body}>
        <View style={styles.table}>
            {/* Lista de Empresas */}
            {data.extratoDetalhes.map((detalhe, index) => (
              <View key={index}>
                {/* Informações da Empresa */}
                <View style={styles.empresaHeader}>
                  <Text>Empresa: {detalhe.empresaNome} - {detalhe.empresaCnpj}</Text>
                  <Text>Produto: {detalhe.produto}</Text>
                  <Text>Quantidade de Créditos: {detalhe.totalMovimentos}</Text>
                </View>

                {/* Tabela de Movimentos */}
                <View style={styles.table}>
                  {/* Cabeçalho da Tabela */}
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Valor do Crédito</Text>
                    <Text style={styles.tableCell}>Cartão - Final</Text>
                    <Text style={styles.tableCell}>Data do Pedido</Text>
                    <Text style={styles.tableCell}>Data do Crédito</Text>
                    <Text style={styles.tableCell}>Descrição</Text>
                    <Text style={styles.tableCell}>Situação</Text>
                  </View>

                  {/* Dados da Tabela */}
                  {detalhe.movimentos.map((movimento, movimentoIndex) => (
                    <View style={styles.tableRow} key={movimentoIndex}>
                      <Text style={styles.tableCell}>
                        {movimento.valorRecargaFormatado}
                      </Text>
                      <Text style={styles.tableCell}>
                        {movimento.numeroCartao}
                      </Text>
                      <Text style={styles.tableCell}>
                        {movimento.dataPedidoFormatado}
                      </Text>
                      <Text style={styles.tableCell}>
                        {movimento.dataCreditoFormatada}
                      </Text>
                      <Text style={styles.tableCell}>{movimento.descricao}</Text>
                      <Text style={styles.tableCell}>{movimento.status}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  </Document>
);

const RenderPdf = () =>{
  const [data, setData] = useState<ExtratoCreditosDto>({} as ExtratoCreditosDto)
  const [dadosCarregado, setDadosCarregado] = useState(false);

  const valoresIniciaisFiltro = {
    cpf: '',
  } as any;

  const filtroValidacoes = Yup.object().shape({
          cpf: Yup.string().required("CPF é obrigatório").min(11, "CPF deve possuir exatamente 11 caracteres").max(11, "CPF deve possuir exatamente 11 caracteres"),
  })

   const formikFiltro = useFormik({
          initialValues: valoresIniciaisFiltro,
          validationSchema: filtroValidacoes,
          onSubmit: async ({cpf}) => {
            try {
              setDadosCarregado(false);
              const { data } = await ClienteService.relatorioExtratoCreditosPorFuncionario(cpf);

              setData(data);
              
              setDadosCarregado(true);

            } catch (error) {
                setDadosCarregado(false);
                  Swal.fire(
                      Array.isArray(error) ? error[0] : `Não foi possivel carregar o relatório`
                  );
            }
          }
      });
  
  return (
      <>
        <HeaderContent title={"Extrato Total de Créditos"} />
        <PainelFiltro>
          {
            <InputCPF 
                    formik={formikFiltro}
                    label="CPF"
                    name="cpf"
                    className="col col-md-2 mb-2"
                    placeholder="000.000.000-00"
                    required={true} />
          }
          {
            <BotaoSubmitFormulario 
                label='BUSCAR'
                labelSubmiting='BUSCANDO ...'
                formik={formikFiltro}
            />
          }
        </PainelFiltro>
        {dadosCarregado && (
            <>
              <PDFDownloadLink
                document={<RelatorioRecargaPdf data={data}/>}
                fileName="relatorio.pdf"
              >
                {({ loading }) => (loading ? 'Carregando documento...' : 'Baixar PDF')}
              </PDFDownloadLink>
              <PDFViewer width="100%" height="600">
                <RelatorioRecargaPdf data={data}/>
              </PDFViewer>
            </>
          )}
      </>
      
    );
};

export default RenderPdf;