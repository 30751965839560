import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputDatePicker from "../../components/InputDatePicker";
import * as Yup from 'yup';
import clsx from "clsx";
import * as XLSX from 'xlsx'
import PainelFiltro from "../../components/PainelFiltro";
import ClienteService from "../../../services/ClienteService";
import { IRelatorioCreditoFuncionarioRecargaResponse } from "../../../interfaces/responses/clientes/IRelatorioCreditoFuncionarioRecargaResponse";
import { IRelatorioCreditoFuncionarioResponse } from "../../../interfaces/responses/clientes/IRelatorioCreditoFuncionarioResponse";
import InputCPF from "../../components/InputCPF";
import { useParams } from "react-router-dom";
import { MovimentoExtratoFuncionarioDTO, MovimentoExtratoFuncionarioDictionary } from "../../../interfaces/responses/clientes/ICartaoResponse";
import RenderFuncionarioExtratoPdf from "./FuncionarioExtratoPdf";

interface Params{
    cpf: string;
}
export function FuncionarioExtrato(){
    const [data, setExtrato] = useState<MovimentoExtratoFuncionarioDictionary>({} as MovimentoExtratoFuncionarioDictionary)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [pdf, setPdf] = useState<boolean>(false)

    let params = useParams<Params>();
  
    useEffect(() => {
        carregarExtrato();
    }, []);

    async function carregarExtrato(){
        try {
            setLoadingInicial(true)
            const { data } = await ClienteService.obterExtrato(params.cpf);
            setExtrato(data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possivel carregar os dados',
                text: Array.isArray(error) ? error[0] : `Atualize a pagina, caso o erro continue entre em contato com o administrador.`,
                showConfirmButton: true,
              })
        }finally {
            setLoadingInicial(false)
        } 
    }

    const colunas = [
        {
            name: 'Numero Cartão',
            selector: (row: MovimentoExtratoFuncionarioDTO) => row['numeroCartao'],
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Descrição',
            selector: (row: MovimentoExtratoFuncionarioDTO) => row['descricao'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Data',
            selector: (row: MovimentoExtratoFuncionarioDTO) => row['data'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: MovimentoExtratoFuncionarioDTO) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Tipo',
            selector: (row: MovimentoExtratoFuncionarioDTO) => row['tipo'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Autorizador',
            selector: (row: MovimentoExtratoFuncionarioDTO) => row['autorizador'],
            sortable: true,
            wrap: true,
        },
    ]

    function DownloadFile(){
        let dataParaPlanilha: any[] = [];
       
        Object.keys(data).forEach(key => {
            const movimentos = data[key];
            dataParaPlanilha.push({
                Ano: key
            });
            movimentos.forEach(movimento => {
                dataParaPlanilha.push({
                    NumeroCartao: movimento.numeroCartao,
                    Descricao: movimento.descricao,
                    Data: movimento.data,
                    Valor: movimento.valor,
                    Tipo: movimento.tipo,
                    Autorizador: movimento.autorizador    
                })
            });
        });
        let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
        let workbook = XLSX.utils.book_new();
        let fileName = `extrato-${params.cpf}.ods`;

        XLSX.utils.book_append_sheet(workbook, worksheet, "extrato");

        XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    }

    return (
        <>
        <HeaderContent title={`Extrato Completo - ${params.cpf}`} >
        {data &&
                <>
                    <button 
                        className='btn btn-sm btn-icon btn-secondary my-1 me-3'
                        onClick={() => DownloadFile()}>
                        <i style={{fontSize: 24}} className="bi bi-printer-fill"></i>
                    </button>
                    
                    <button 
                        className='btn btn-sm btn-icon btn-secondary my-1'
                        onClick={() => setPdf(!pdf)}>
                        <i style={{fontSize: 24}} className="bi bi-file-pdf-fill"></i>
                    </button>
                </>
                        }
        </HeaderContent>
          {loadingInicial && data ? (
                    <Spinner animation='border' />
                ) : (
                    <>
                    {pdf ? 
                        (<>
                            <RenderFuncionarioExtratoPdf data={data} />
                        </>) : 
                        (<>
                            {Object.keys(data).map(key => (
                                <div key={key}>
                                    <h4>Movimentos - {key}</h4>
                                    <GridPadrao 
                                        colunas={colunas} 
                                        tipo='Movimentos' 
                                        itens={data[key]}  
                                    />
                                </div>
                            ))}
                        </>)
                    }
                </>
                )
            }
        </>
    )
}
