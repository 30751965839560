/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { AsideMenuItem } from '../AsideMenuItem'

export function AsideMenuFinanceiro() {
  return (
    <>
      <AsideMenuItem
        to='/financeiro/clientes'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Clientes"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/financeiro/credenciados'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Credenciados"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/financeiro/credenciado/movimentos'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Relatório Movimento"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/financeiro/cliente/recargas'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Relatório Recargas Por Cliente"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/financeiro/grupo/recargas'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Relatório Recargas Por Grupo"
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

