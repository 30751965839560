import React, { ChangeEvent, useEffect, useState } from 'react';
import { IFinanceiroGrupoEmpresarial } from '../../../interfaces/responses/financeiro/IFinanceiroGrupoEmpresarial';
import FinanceiroService from '../../../services/FinanceiroService';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import HeaderContent from '../../components/HeaderContent';
import PainelFiltro from '../../components/PainelFiltro';
import InputCNPJ from '../../components/InputCNPJ';
import FormInputSelect from '../../components/FormInputSelect';
import clsx from "clsx";
import * as Yup from 'yup';
import InputDatePicker from '../../components/InputDatePicker';
import { Spinner } from 'react-bootstrap-v5';
import GridPadrao from '../../components/GridPadrao';
import * as XLSX from 'xlsx';
import { TipoGrupoEmpresarial } from '../../../utils/Constants';
import { IRelatorioRecargaGrupo } from '../../../interfaces/responses/financeiro/IRelatorioRecargaCliente';

export default function FinanceiroRelatorioRecargaGrupo(){
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [gruposEmpresariais, setGruposEmpresarias] = useState<IFinanceiroGrupoEmpresarial[]>([]);
    const [relatorioMovimento, setRelatorioMovimento] = useState<IRelatorioRecargaGrupo[]>([]);
    const [exportarCSV, setExportarCSV] = useState<boolean>(false)

    useEffect(() => {
        carregarGrupos();
      }, [])

    async function carregarGrupos(){
    try {
        setLoadingInicial(true)
        
        const { data } = await FinanceiroService.obterGruposEmpresariais()

        setGruposEmpresarias(data);

        } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Não foi possivel carregar os grupos',
            text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
            showConfirmButton: true,
        })
        } finally {
        setLoadingInicial(false)
        } 
    }

    const valoresIniciaisFiltro = {
        dataInicio: '',
        dataFim: '',
        idGrupoEmpresarialCliente:''
    } as any;

    const filtroValidacoes = Yup.object().shape({
        dataInicio: Yup.date().required('Data Inicio é obrigatória').test('validacao-range', 'Data Inicio deve ser menor que o Data Fim', async (value) => {
            if (!value || !formikFiltro.values.dataFim) {
                return true;
            }

            if (value.getTime() <= formikFiltro.values.dataFim.getTime()) {
                return true;
            }

            return false;
        }),
        dataFim: Yup.date().required('Data Fim é obrigatória').test('validacao-range', 'Data Fim deve ser maior que o Data Inicio', async (value) => {
            if (!formikFiltro.values.dataInicio || !value) {
                return true;
            }

            if (formikFiltro.values.dataInicio.getTime() <= value.getTime()) {
                return true;
            }

            return false;
        })
    })

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({idGrupoEmpresarialCliente, dataInicio, dataFim}) => {
          try {
            
            setLoadingInicial(true)
            
            const { data } = await FinanceiroService.relatorioRecargaGrupo(
                                                                    idGrupoEmpresarialCliente,
                                                                    new Date(dataInicio).toLocaleDateString('pt-br'), 
                                                                    new Date(dataFim).toLocaleDateString('pt-br'));
            
            
            setRelatorioMovimento(data);
            
            setExportarCSV(true)
          
          } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel carregar o relatório`
                );
          } finally {
            setLoadingInicial(false)
          } 
        }
    });

    const colunas = [
        {
            name: 'Grupo',
            selector: (row: IRelatorioRecargaGrupo) => row['nome'],
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            name: 'Valor Total Grupo',
            selector: (row: IRelatorioRecargaGrupo) => row['valorTotalRecargaFormat'],
            sortable: true,
            wrap: true,
            grow: 2
        },
    ]

      function DownloadFile(){
        
        let dataParaPlanilha: any[] = [];
        
        relatorioMovimento.forEach(item => {
            dataParaPlanilha.push({
                GrupoCliente: item.nome,
                TotalRecargaPeriodo: item.valorTotalRecarga,
            })
        });
        
        let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
        let workbook = XLSX.utils.book_new();
        let fileName = `relatorio-recarga-grupo
                        ${new Date(formikFiltro.values.dataInicio).toLocaleDateString('pt-br')}-
                        ${new Date(formikFiltro.values.dataFim).toLocaleDateString('pt-br')}
                        .ods`;

        XLSX.utils.book_append_sheet(workbook, worksheet, "relatorio-recarga-grupo");

        XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    }

    return <>
        <HeaderContent title={"Relatório de Recargas por Grupo"} />
        <PainelFiltro>
            {
                <>
                    <div className="col col-md-2">
                            <label className='form-label fw-bold required'>Data Inicio</label>
                            <InputDatePicker selected={formikFiltro.values.dataInicio} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataInicio', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataInicio', date);
                                }

                                await formikFiltro.setFieldTouched('dataInicio');

                            }} className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataInicio && !formikFiltro.errors.dataInicio,

                                }

                            )} id="form-data-sorteio" />
                            {formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataInicio}</span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="col col-md-2">
                    <label className='form-label fw-bold required'>Data Fim</label>
                        <InputDatePicker selected={formikFiltro.values.dataFim} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataFim', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataFim', date);
                                }

                                await formikFiltro.setFieldTouched('dataFim');

                            }}  className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataFim && formikFiltro.errors.dataFim,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataFim && !formikFiltro.errors.dataFim,

                                }

                            )} id="form-data-fim" />
                            {formikFiltro.touched.dataFim && formikFiltro.errors.dataFim && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataFim}</span>
                                    </div>
                                </div>
                            )}
                    </div>
                   
                    <FormInputSelect disabled={formikFiltro.isSubmitting} values={
                            gruposEmpresariais
                            .filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO)
                            .map(grupo => ({
                                value: grupo.id,
                                label: grupo.nome,
                            }))
                        } formik={formikFiltro} className="col col-md-2" propName='idGrupoEmpresarialCliente' label='Grupo Cliente' loading={loadingInicial} />
                </>
            }
            {
                <>
                    <button data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                        onClick={formikFiltro.submitForm}
                        disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                        <i className="icon-lg fa fa-filter"></i>
                        Filtrar
                    </button>
                    {relatorioMovimento.length > 0  && exportarCSV &&
                            <button 
                                className='btn btn-sm btn-icon btn-secondary my-1'
                                onClick={() => DownloadFile()}
                            >
                                <i style={{fontSize: 24}} className="bi bi-printer-fill">
                            </i></button>
                        }
                </>
            }
        </PainelFiltro>
        {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Recargas' itens={relatorioMovimento} />
          </div>
        )}
    </>
}