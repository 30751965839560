import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image  } from '@react-pdf/renderer';
import { PDFViewer, PDFDownloadLink  } from '@react-pdf/renderer';
import { MovimentoExtratoFuncionarioDictionary } from '../../../interfaces/responses/clientes/ICartaoResponse';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 10,
      padding: 0,
      backgroundColor: '#ffffff',
      lineHeight: 1.5
    },
    header: {
      backgroundColor: '#00A946',
      color: '#ffffff',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerRow: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    headerTitle: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10,
    },
    column: {
      width: '48%',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 4,
    },
    body: {
      marginVertical: 20,
      padding: 10
    },
    table: {
      display: 'flex',
      width: '100%',
      borderWidth: 1,
      borderColor: '#000',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCell: {
      flex: 1,
      padding: 5,
      borderWidth: 1,
      borderColor: '#000',
    },
    footer: {
      position: 'absolute', // Fixa no rodapé
      bottom: 0,
      left: 10,
      top: 10,
      textAlign: 'center', // Centraliza o texto
      fontSize: 10,
    },
    logo: {
      width: 25,
      height: 25,
      position: 'absolute',
      top: 10,
      right: 10,
    },
    empresaHeader: {
      fontWeight: 'bold',
      padding: 10
    },
    produto: {
      padding: 10,
    },
});

interface RelatorioRecargaPdfProps {
  data: MovimentoExtratoFuncionarioDictionary;
  nome?: string;
  cpf?: string;
}

const FuncionarioExtratoPdf = ({ data, nome, cpf }: RelatorioRecargaPdfProps) => (
    <Document>
    <Page size="A4" orientation='portrait' style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Extrato Completo</Text>
        <View style={styles.headerRow}>
            <View style={styles.column}>
                <Text>Funcionário: {nome}</Text>
            </View>
            <View style={styles.column}>
                <Text>Documento: {cpf}</Text>
            </View>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
        />
        <Image
          style={styles.logo}
          src='https://qbcard-public.s3.sa-east-1.amazonaws.com/logo/logo-sem-letra.png'
        />
      </View>

      {/* Body */}
      <View style={styles.body}>
        <View style={styles.table}>
            {/* Lista de Empresas */}
            {Object.keys(data).map((key, index) =>(
              <View key={index}>
                {/* Informações da Empresa */}
                <View style={styles.empresaHeader}>
                  <Text>Movimentações - {key}</Text>
                </View>

                {/* Tabela de Movimentos */}
                <View style={styles.table}>
                  {/* Cabeçalho da Tabela */}
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Cartão Final</Text>
                    <Text style={styles.tableCell}>Descrição</Text>
                    <Text style={styles.tableCell}>Data</Text>
                    <Text style={styles.tableCell}>Valor</Text>
                    <Text style={styles.tableCell}>Tipo</Text>
                    <Text style={styles.tableCell}>Autorizador</Text>
                  </View>

                  {/* Dados da Tabela */}
                  {data[key].map((movimento, index) => (
                    <View style={styles.tableRow} key={index}>
                      <Text style={styles.tableCell}>
                        {movimento.ultimosDigitosCartao}
                      </Text>
                      <Text style={styles.tableCell}>
                        {movimento.descricao}
                      </Text>
                      <Text style={styles.tableCell}>
                        {movimento.data}
                      </Text>
                      <Text style={styles.tableCell}>
                        {movimento.valorFormatado}
                      </Text>
                      <Text style={styles.tableCell}>{movimento.tipo}</Text>
                      <Text style={styles.tableCell}>{movimento.autorizador}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  </Document>
);

const RenderFuncionarioExtratoPdf = ({ data }: RelatorioRecargaPdfProps) =>{
    const primeiraChave = Object.keys(data)[0];
    const primeiroRegistro = primeiraChave ? data[primeiraChave][0] : null;
    const nome = primeiroRegistro ? primeiroRegistro.nome : '';
    const cpf = primeiroRegistro ? primeiroRegistro.cpf : '';

    console.log(cpf)
    console.log(nome)
    console.log(primeiraChave)
    console.log(primeiroRegistro)
  return (
      <>
        <PDFDownloadLink
            document={<FuncionarioExtratoPdf data={data} nome={nome} cpf={cpf}/>}
            fileName="relatorio.pdf">
            {({ loading }) => (loading ? 'Carregando documento...' : 'Baixar PDF')}
        </PDFDownloadLink>
        <PDFViewer width="100%" height="600">
            <FuncionarioExtratoPdf data={data} nome={nome} cpf={cpf}/>
        </PDFViewer>
      </>
    );
};

export default RenderFuncionarioExtratoPdf;